import {defineStore} from 'pinia';
import notify from "~/utils/notify";
import {
    LINK_TYPE_ERC20, LINK_TYPE_OMNI, LINK_TYPE_TRC20, SUCCESS_CODE,
} from "~/consts/const";
import copy from "copy-to-clipboard";
import {LangStore} from "~/common-store/lang-store";
import axios from "~/helpers/axios";
import {EnvStore} from "~/common-store/env-store";
import CryptoJS from 'crypto-js'
import dayjs from "dayjs";

export const CashierUsdtStore = defineStore('cashier-usdt-store-kpqCQ', {
    state: () => ({
        orderInfo: {},
        fetchOrderInfoIng: false,
        qrCodeValue: 'asdasdad',
        qrCodeLoading: false,
        feeInfoModalVisible: false, //提币手续费 弹框
        doSubForm: {
            walletInfo: {
                LinkType: 0,
                Address: '',
                WalletId: 0,
            },
            orderNo: '',

        },
        k: '',
        platformWalletList: [],
        fetchPlatformWalletListIng: false,
        doWalletSubIng: false,
    }),
    actions: {
        copyAmount() {
            copy(this.orderInfo.MbrDoUsdtAmount);
            const langStore = LangStore();
            notify.success(langStore.t("6NQEnkne4z"));
        },
        copyWalletAddress() {
            copy(this.doSubForm.walletInfo.Address);
            const langStore = LangStore();
            notify.success(langStore.t("dgxF0piubU"));
        },
        feeInfoModalShow() {
            this.feeInfoModalVisible = true;
        },
        doWalletSelect(walletId) {
            const walletInfo = this.platformWalletList.find(item => item.WalletId === walletId);
            this.doSubForm.walletInfo = walletInfo
            this.doWalletSub()
        },
        doWalletSub() {
            if (this.doSubForm.walletId === 0) {
                notify.err('shmJFRvPUu')
                return
            }
            if (this.doWalletSubIng) {
                return
            }
            this.doWalletSubIng = true
            const conf = EnvStore().config;
            let bodyData = {
                OrderNo: this.doSubForm.orderNo,
                LinkType: this.doSubForm.walletInfo.LinkType,
                WalletId: this.doSubForm.walletInfo.WalletId,
            }
            axios({
                method: 'post',
                url: `${conf.ServerHost}/api/v1/do/merch/usdt-do-wallet-p-submit`,
                data: bodyData,
                headers: {
                    'API-KEY': this.k,
                    'Content-Type': 'application/json',
                },
            }).then(async resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp);
                    return
                }
                // await this.fetchOrderInfo()
                // notify.success('kQ3fsyoDm6');
            }).catch(async (err) => {
                // notify.error('zR52WEbX2m');
                console.log('/api/v1/do/merchant/do-wallet-submit', err)
            }).finally(() => {
                this.doWalletSubIng = false;
            })
        },
        async doWalletPSub() {
            await this.fetchOrderInfo()
            notify.success('kQ3fsyoDm6');
        },
        async fetchPlatformWallet() {
            if (this.fetchPlatformWalletListIng) {
                return
            }
            this.fetchPlatformWalletListIng = true
            const conf = EnvStore().config;
            let currTimestamp = dayjs().unix()
            let signString = `GET\n\n\n${currTimestamp}\n`
            console.log(signString)
            let signed = CryptoJS.HmacSHA1(signString, this.k)
            const base64Signed = CryptoJS.enc.Base64.stringify(signed)
            await axios({
                method: 'get',
                url: `${conf.ServerHost}/api/v1/do/merch/wallet-list`,
                headers: {
                    'API-KEY': this.k,
                    'Timestamp': currTimestamp,
                    'Sign': base64Signed,
                },
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp);
                    return
                }
                const platformWalletMap = resp.data.Data
                if (platformWalletMap[LINK_TYPE_TRC20]) {
                    this.platformWalletList.push(platformWalletMap[LINK_TYPE_TRC20])
                }
                if (platformWalletMap[LINK_TYPE_ERC20]) {
                    this.platformWalletList.push(platformWalletMap[LINK_TYPE_ERC20])
                }
                if (platformWalletMap[LINK_TYPE_OMNI]) {
                    this.platformWalletList.push(platformWalletMap[LINK_TYPE_OMNI])
                }

                if (this.platformWalletList.length > 0) {
                    this.doWalletSelect(this.platformWalletList[0].WalletId)
                }
            }).catch(async (err) => {
                console.log('/api/v1/do/merchant/wallet-list', err)
            }).finally(() => {
                this.fetchPlatformWalletListIng = false;
            })
        },
        async fetchOrderInfo() {
            if (this.fetchOrderInfoIng) {
                return;
            }
            this.fetchOrderInfoIng = true;

            const conf = EnvStore().config;
            let currTimestamp = dayjs().unix()
            let signString = `GET\n\n\n${currTimestamp}\nOrderNo=${this.doSubForm.orderNo}`
            let signed = CryptoJS.HmacSHA1(signString, this.k)
            const base64Signed = CryptoJS.enc.Base64.stringify(signed)
            await axios({
                method: 'get',
                url: `${conf.ServerHost}/api/v1/do/merch/usdt-do-detail`,
                params: {
                    OrderNo: this.doSubForm.orderNo,
                },
                headers: {
                    'API-KEY': this.k,
                    'Timestamp': currTimestamp,
                    'Sign': base64Signed,
                },
            }).then(resp => {
                if (resp.data.Code !== SUCCESS_CODE) {
                    notify.apiErr(resp);
                    return
                }
                this.orderInfo = resp.data.Data;
            }).catch(async (err) => {
                console.log('/api/v1/do/merchant/usdt-dispatch-order-detail', err)
            }).finally(() => {
                this.fetchOrderInfoIng = false;
            })
        },
    },
});
